import dynamic from "next/dynamic";
import React from "react";
import { ErrorLock } from "../app/ErrorLock";
import { AspectRatio } from "../ui/AspectRatio";

const VideoPlayer = dynamic(() => import("~/components/ui/Player/VideoPlayer"));

export const BlockVideo = ({ block }: { block: any }) => {
  if (
    (block.type === "youtube_url" || block.type === "vimeo_url") &&
    !(block.type === "youtube_url" || block?.url?.trim())
  )
    return <></>;

  if (
    block.type === "vimeo_api" &&
    (typeof block?.vimeo_api?.files !== "object" ||
      !Object.keys(block?.vimeo_api?.files).length)
  )
    return <></>;

  return (
    <AspectRatio ratio="16 / 9" bgColor="#000">
      <ErrorLock>
        {block.type === "vimeo_api" && (
          <VideoPlayer
            src={block?.vimeo_api?.files}
            captions={block?.vimeo_api?.captions}
            vimeoId={block?.vimeo_api?.vimeo_id}
            mode={block?.objectFit === "contain" ? "fill" : undefined}
            posterSizes={block?.vimeo_api?.poster?.sizes}
            type={block.type}
          />
        )}
        {(block.type === "youtube_url" || block.type === "vimeo_url") && (
          <VideoPlayer
            src={`${block.url.replace("manage/videos/", "")}?background=1`}
            type={block.type}
          />
        )}
      </ErrorLock>
    </AspectRatio>
  );
};
export default BlockVideo;
