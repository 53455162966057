import Link from "next/link";
import React from "react";
import styled, { StyledComponent } from "styled-components";
import { Badge } from "./Badge";
import SafeHtmlSpan from "./SafeHtmlSpan";

const A = styled.a<{ maxWidth?: string }>`
  display: block;
  line-height: 2px;
  text-decoration: none;
  width: 100%;

  @media (any-pointer: fine) {
    &:hover > span {
      background-color: #000;
      color: #fff;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          max-width: ${
            props.maxWidth ? props.maxWidth : props.theme.spacePx(breakpoint, 2)
          };
        `;
    })}
`;

export const ButtonLink = ({
  url,
  title,
  newWindow,
  space,
  heading,
  fullWidth,
  WrapWith,
  maxWidth,
}: {
  url: string;
  title: string;
  space: number;
  heading: string;
  newWindow?: boolean;
  fullWidth?: boolean;
  maxWidth?: boolean;
  WrapWith?: StyledComponent<any, any>;
}) => {
  let out: any = <></>;

  if (url && url.trim()) {
    const badge = (
      <Badge
        space={space}
        heading={heading}
        fullWidth={fullWidth}
        maxWidth={!!maxWidth}
      >
        <SafeHtmlSpan html={title} />
      </Badge>
    );

    out = out = (
      <A
        href={url.indexOf("/") !== 0 ? url : undefined}
        target={newWindow ? "_blank" : undefined}
        rel={newWindow ? "noreferrer" : undefined}
        maxWidth={!maxWidth ? "100%" : undefined}
        onClick={
          url.indexOf("#") === 0
            ? (event: any) => {
                const hash = event?.currentTarget?.getAttribute("href");

                event.preventDefault();
                const target = document.querySelector(hash);

                if (target) {
                  const header: HTMLDivElement | null =
                    document.querySelector(".header.with-hero");
                  const subMenu: HTMLDivElement | null = document.querySelector(
                    ".header.with-hero .subMenu"
                  );
                  let adjust = -30;
                  if (header) {
                    if (window.scrollY < target.offsetTop && subMenu) {
                      adjust -= subMenu.offsetHeight;
                    } else {
                      adjust -= header.offsetHeight;
                    }
                  }

                  window.scrollTo({
                    top: target.offsetTop + adjust,
                    behavior: "smooth",
                  });
                }
              }
            : undefined
        }
      >
        {badge}
      </A>
    );

    if (url.indexOf("/") === 0) {
      out = (
        <Link href={url} passHref>
          {out}
        </Link>
      );
    }
  }

  if (out) return WrapWith ? <WrapWith>{out}</WrapWith> : out;

  return <></>;
};
