import React from "react";
import styled from "styled-components";
import { Heading } from "./Heading";

const BadgeContainer = styled.span<{
  color: string;
  heading: string;
  backgroundColor: string;
  space: number;
  fullWidth: boolean;
  maxWidth: boolean;
}>`
  display: ${({ fullWidth }) => (fullWidth ? "flex" : "inline-flex")};
  text-transform: uppercase;
  background-color: ${({ backgroundColor }) => backgroundColor};
  transition: background-color 0.3s, color 0.3s;
  color: ${({ color }) => color};
  justify-content: ${({ fullWidth }) => (fullWidth ? "center" : "flex-start")};
  padding: 0 0.4em;
  position: relative;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
          height: ${props.theme.spacePx(breakpoint, props.space)};
          ${props.maxWidth ? `max-width: ${props.theme.spacePx(breakpoint, 2)};` : ""}

          & > div {
            margin-top: calc(${props.theme.spaceFontTop(
              breakpoint,
              `${props.heading}Heading`
            )} * -0.25);
          }   
        `;
    })}

  @media (any-pointer: fine) {
    a:hover & {
      color: #fff !important;
      background-color: #000;
    }
  }
`;

export const Badge = ({
  children,
  color = "#000",
  backgroundColor = "var(--ikon-hl-color, #ff0)",
  space,
  heading,
  fullWidth,
  maxWidth,
}: {
  children: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  space: number;
  maxWidth?: boolean;
  heading: string;
  fullWidth?: boolean;
}) => {
  return (
    <BadgeContainer
      fullWidth={!!fullWidth}
      maxWidth={!!maxWidth}
      space={space - 1}
      backgroundColor={backgroundColor}
      color={color}
      heading={heading}
    >
      <Heading heading={heading} space={space} inline noWrap>
        {children}
      </Heading>
    </BadgeContainer>
  );
};
