import React from "react";
import dynamic from "next/dynamic";
import { useSettingsContext } from "~/providers/SettingsContextProvider";

import { AspectRatio } from "./AspectRatio";

const GoogleMap = dynamic(() => import("./GoogleMap"));

export const Map = ({
  lat,
  lng,
  zoom,
  ratio,
}: {
  lat: any;
  lng: any;
  zoom?: number;
  ratio: string;
}) => {
  const settings = useSettingsContext();

  if (
    !settings?.options?.googleMapsApiKey ||
    `${settings?.options?.googleMapsApiKey}`.trim() === ""
  )
    return <></>;

  return (
    <AspectRatio ratio={ratio} bgColor="#f2f2f2">
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <GoogleMap
          lat={lat}
          lng={lng}
          zoom={zoom}
          apiKey={settings?.options?.googleMapsApiKey}
        />       
      </div>
    </AspectRatio>
  );
};
