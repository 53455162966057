import dynamic from "next/dynamic";
import React from "react";
import styled from "styled-components";

const AudioPlayer = dynamic(() => import("~/components/ui/Player/AudioPlayer"));

const AudioContainer = styled.div`
  background-color: var(--ikon-bg-color, #fff);
  position: relative;
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 4)};
      `;
    })}
`;

export const BlockAudio = ({ block }: { block: any }) => {
  if (!block?.mp3?.url || block?.mp3?.mime_type?.indexOf("audio") === -1)
    return <></>;

  return (
    <AudioContainer>
      <AudioPlayer
        src={block?.mp3?.url}
        title={`<b>AUDIO</b>&emsp;${block?.mp3?.title}` ?? "Audio"}
      />
    </AudioContainer>
  );
};

export default BlockAudio;
