import styled from "styled-components";

export const FlexibleHeading = styled.h2<{ heading: string }>`
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
      ${props.theme.textStyle(breakpoint, `${props.heading}Heading`)};
      margin-top: ${props.theme.spaceFontTop(
        breakpoint,
        `${props.heading}Heading`
      )};
      margin-bottom: ${props.theme.spaceFontBottom(
        breakpoint,
        7,
        `${props.heading}Heading`
      )};

      margin-left: ${props.heading === "h3" ? "0" : "-0.04em"};
    `;
    })}
`;
